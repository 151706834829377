import React from "react";
import "./services.css";

import { ReUsableCard6 } from "@inertiasoft/re-usable-cards";
import { card6 } from "../../constants/cards";

class Services extends React.Component {
  title = "About Us";

  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="Services">
        <div className="row services">
          <div className="col-md-12 ">
            <h2>
              WHAT WE <span className="x-men-color">DO</span>
            </h2>
            <br />
            <p className="serviceText">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Suspendisse et justo. Praesent mattis commodo augue.
            </p>
          </div>
        </div>
        <br />
        <br />
        <div className="row justify-content-center flex-wrap">
          {/* {services.whatWeDoDetails.map((item) => (
            <div className="col-lg-3">
              <ReUsableCard12 data={item} />
            </div>
          ))} */}
          {card6.map((item, index) => (
            <div className="col-lg-3" key={index}>
              <ReUsableCard6 data={item} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Services;
