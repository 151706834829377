import axios from "axios";

import config from '../constants/config';

const baseURL = config.backendUrl;

const contactUsApiIntegration = (fullName, email, query, mobileNo) => {
	const data = {
		fullName,
		email,
		mobileNo,
		query,
	};

	return axios.post(`${baseURL}/contact-us`, data).then((response) => {
		return response.data;
	}).catch(err => err);
};

export default {
	contactUsApiIntegration
};
