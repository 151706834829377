import FacebookIcon from "../assets/facebook.png";

export const contactForm1 = {
  color: "white",
  backgroundColor: "#301934",
  formHeading: "Okay",
  btnContent: "Forward",
  btnColor: "#301934",
  btnBackground: "white",
  nameLabelText: "Username",
  emailLabelText: "Email",
  subjectLabelText: "Sub",
  messageLabelText: "Enter Message",
};

export const contactForm2 = {
  headingColor: "white",
  headingBgColor: "#301934",
  btnContent: "Send",
  addressFontColor: "#black",
  iconBgColor: "#301934",
  btnColor: "white",
  btnBgColor: "#301934",
  nameLabelText: "Username",
  emailLabelText: "Email",
  subjectLabelText: "Sub",
  messageLabelText: "Enter Message",
  addressText: "abc",
  countryText: "us",
  number: "12312312",
  timings: "6:00",
  email: "@asdasdasdas",
  email2: "@saasdasdasd",
  mapSrc:
    "https://maps.google.com/maps?q=Karachi&t=&z=15&ie=UTF8&iwloc=&output=embed",
};

export const contactForm3 = {
  namePlaceholder: "Name",
  emailPlaceholder: "Email",
  numberPlaceholder: "1231231231",
  messagePlaceholder: "Message",
  btnText: "Submit",
  content: [
    {
      title: "OUR RESTURANT",
      icon: "fa fa-map-marker",
      description:
        "Plot # 21-22, Allama Qazi Chowk, Block-A, Sindhi Muslim Co-operative Housing Society, Karachi.",
    },
    {
      title: "GENERAL ENQUIRIES",
      icon: "fa fa-paperclip",
      description: "info@foodsinn.co",
    },
    {
      title: "CALL US",
      icon: "fa fa-mobile-phone",
      description: "11:00 AM TO 02:00 AM",
    },
    {
      title: "OPERATION HOURS",
      icon: "fa fa-clock-o",
      description: "11:00 AM TO 02:00 AM",
    },
  ],
};

export const contactForm4 = {
  imgSrc: "assets/images/img-01.png",
  heading: "Contact Us",
  btnContent: "Forward Email",
  namePlaceholder: "Name",
  emailPlaceholder: "Email",
  subjectPlaceholder: "Subject",
  messagePlaceholder: "Message",
};

export const contactForm5 = {
  backgroundImage: "assets/images/img-01.png",
  heading: "Contact Us",
  btnContent: "Forward Email",
  nameLabelText: "Name",
  emailLabelText: "Email",
  messageLabelText: "Message",
  background:
    "linear-gradient(45deg,rgba(213, 0, 125, 0.8),rgba(229, 57, 53, 0.8))",
};

export const contactForm7 = {
  title: ["CONNECT WITH", " US"],
  contactDetails: {
    contactNo: "+123456789101",
    email: "info@inertiasoft.net",
  },
  socialIcons: [
    {
      imageSrc: `${FacebookIcon}`,
      href: "www.facebook.com",
    },
    {
      imageSrc:
        "https://ddo0fzhfvians.cloudfront.net/uploads/icons/png/19042250241579774616-512.png",
      href: "www.facebook.com",
    },
  ],
  textColor: "#ffff",
  buttonText: "Submit",
  buttonBackgroundColor: "#FE9338",
  backgroundImageSrc: "",
  namePlaceholder: "Name *",
  messagePlaceholder: "Message *",
  emailPlaceholder: "Email *",
  subHeading: "Write to us",
};

export const contactForm8 = {
  namePlaceholder: "name",
  messagePlaceholder: "message",
  emailPlaceholder: "Email",
  backgroundColor: "transparent",
  btnBackground: "rgb(104, 120, 214)",
  btnContent: "Submit",
  map_src:
    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Block%2013A%20Gulshan-e-Iqbal,%20Karachi,%20Pakistan+(My%20Business%20Name)&t=&z=14&ie=UTF8&iwloc=B&output=embed",
  map_title: "Block-13/A, Gulshan-e-Iqbal, Karachi, Pakistan​",
  map_aria_label: "Block-13/A, Gulshan-e-Iqbal, Karachi, Pakistan",
};

export const contactForm9 = {
  heading: "Get in Touch",
  labelName: "Name",
  labelEmail: "Email",
  labelSubject: "Subject (optional)",
  labelMessage: "Message",
  btnContent: "Send Message",
};
