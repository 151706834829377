/* eslint-disable react/state-in-constructor */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import "./contact-form.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Snackbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ReUsableContactUsForm7 } from "@inertiasoft/re-usable-contactus-forms";

import {
  contactUsApiIntegration,
  clearSnackbar,
} from "../../store/actions/contact";
import store from "../../store/store";

import { contactForm7 } from "../../constants/contact";

class ContactForm extends React.Component {
  title = "";

  state = {
    formSubmitted: false,
  };

  handleSubmit = ({ fullName, email, query, mobileNo }) => {
    store
      .dispatch(contactUsApiIntegration(fullName, email, query, mobileNo))
      .then(() => {
        window.location.reload();
      });
  };

  render() {
    return (
      <div className="Contact-Form">
        <ReUsableContactUsForm7
          data={contactForm7}
          handleSubmit={this.handleSubmit}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.props.snackBarOpen}
          autoHideDuration={5000}
          onClose={this.props.clearSnackbar}
          message={
            <span id="message-id" style={{ fontSize: "12px" }}>
              {this.props.snackBarMessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.props.onSnackBarClick}
            >
              {" "}
              x{" "}
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    snackbar: state.contact.snackBarOpen,
    snackBarMessage: state.contact.snackBarMessage,
    snackBarOpen: state.contact.snackBarOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSnackBarClick: bindActionCreators(clearSnackbar, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
