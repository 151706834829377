import React from "react";
import "./header.css";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    isloading: state.login.isloading,
  };
}

class Header extends React.Component {
  title = "Welcome to Home Page";

  constructor() {
    super();
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div className="Header">
        <div className="parallax">
          <div className="title">Ready To Explore Creativity</div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Header);
