import React from "react";
import "./header.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function mapStateToProps(state) {
  return {
    isloading: state.login.isloading,
  };
}

class Header extends React.Component {
  title = "Welcome to Home Page";

  constructor() {
    super();
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const { data } = this.props;
    return (
      <div className="Secondry-Header">
        <div className="parallaxx row d-flex justify-content-between align-items-center p-2">
          <div className="col-lg-3 title m-4">{data.title}</div>
          <div className="col-lg-3 title">
            <img src={data.image} alt="header" />
          </div>
          <div className="col-lg-3 description">
            <h3>{data.heading}</h3>
            <br />
            <p>{data.description}</p>
            <hr />
            <ul className="nav d-flex justify-content-center align-items-center">
              <li className="nav-item ">
                <a className="nav-link text-light" href="#portfolio">
                  UNLEASH THE IDEAS
                </a>
                <hr />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps)(Header);
