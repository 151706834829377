const card1 = [
  {
    cardImg: {
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/design-service.svg",
      srcSet:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 100w",
      width: "80",
      height: "80",
      loading: "lazy",
      sizes: "(max-width: 80px) 80vw, 80px",
      ref: "test",
      alt: "Card-Image",
    },
    cardTitle: {
      content: "Design",
    },
    cardData: {
      content:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
    },
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
  {
    cardImg: {
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/design-service.svg",
      srcSet:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 100w",
      width: "80",
      height: "80",
      loading: "lazy",
      sizes: "(max-width: 80px) 80vw, 80px",
      ref: "test",
      alt: "Card-Image",
    },
    cardTitle: {
      content: "Design",
    },
    cardData: {
      content:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
    },
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
  {
    cardImg: {
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/design-service.svg",
      srcSet:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 100w",
      width: "80",
      height: "80",
      loading: "lazy",
      sizes: "(max-width: 80px) 80vw, 80px",
      ref: "test",
      alt: "Card-Image",
    },
    cardTitle: {
      content: "Design",
    },
    cardData: {
      content:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
    },
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
];

const card2 = [
  {
    cardImage: {
      width: "300",
      height: "300",
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-300x300.jpg",
      alt: "Image Card",
      loading: "lazy",
      srcset:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-300x300.jpg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-150x150.jpg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-400x400.jpg 400w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1.jpg 625w",
      sizes: "(max-width: 300px) 100vw, 300px",
    },
  },
  {
    cardImage: {
      width: "300",
      height: "300",
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-300x300.jpg",
      alt: "Image Card",
      loading: "lazy",
      srcset:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-300x300.jpg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-150x150.jpg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-400x400.jpg 400w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1.jpg 625w",
      sizes: "(max-width: 300px) 100vw, 300px",
    },
  },
  {
    cardImage: {
      width: "300",
      height: "300",
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-300x300.jpg",
      alt: "Image Card",
      loading: "lazy",
      srcset:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-300x300.jpg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-150x150.jpg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1-400x400.jpg 400w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/portfolio1.jpg 625w",
      sizes: "(max-width: 300px) 100vw, 300px",
    },
  },
];

const card3 = [
  {
    image: {
      width: "100%",
      height: "100%",
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2.jpg",
      alt: "Designation Card",
      loading: "lazy",
      srcSet:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2.jpg 400w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2-300x300.jpg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2-150x150.jpg 150w",
      sizes: "(max-width: 400px) 100vw, 400px",
    },
    designation: {
      name: "Fred Buster",
      title: "Director OPS",
    },
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
  {
    image: {
      width: "100%",
      height: "100%",
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2.jpg",
      alt: "Designation Card",
      loading: "lazy",
      srcSet:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2.jpg 400w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2-300x300.jpg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2-150x150.jpg 150w",
      sizes: "(max-width: 400px) 100vw, 400px",
    },
    designation: {
      name: "Fred Buster",
      title: "Director OPS",
    },
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
  {
    image: {
      width: "100%",
      height: "100%",
      src:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2.jpg",
      alt: "Designation Card",
      loading: "lazy",
      srcSet:
        "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2.jpg 400w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2-300x300.jpg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/01/team-2-150x150.jpg 150w",
      sizes: "(max-width: 400px) 100vw, 400px",
    },
    designation: {
      name: "Fred Buster",
      title: "Director OPS",
    },
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
];

const card4 = [
  {
    heading: "Who Are We",
    content:
      "Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Ut elit tellus, luctus nec ullam corper mattis, pulvinar. Etiam erat lectus, finibus eget commodo quis, tinci dunt eget leo. Nullam quis vulpu tate orci, ac accum san quam.",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
  {
    heading: "Who Are We",
    content:
      "Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Ut elit tellus, luctus nec ullam corper mattis, pulvinar. Etiam erat lectus, finibus eget commodo quis, tinci dunt eget leo. Nullam quis vulpu tate orci, ac accum san quam.",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
  {
    heading: "Who Are We",
    content:
      "Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Ut elit tellus, luctus nec ullam corper mattis, pulvinar. Etiam erat lectus, finibus eget commodo quis, tinci dunt eget leo. Nullam quis vulpu tate orci, ac accum san quam.",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "33px",
    textColor: "black",
    textSize: "12",
  },
];

const card5 = [
  {
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
    title: "Best Quality Designs",
    content:
      "Sed Fringilla Mauris Sit Amet Nibh. Donec Sodales Sagittis Magna. Sed Consequat, Leo Eget Bibendum, Sodales, Augue Velit Cursus Nunc, Quis Gravida Magna Mi A Libero.",
  },
  {
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
    title: "Best Quality Designs",
    content:
      "Sed Fringilla Mauris Sit Amet Nibh. Donec Sodales Sagittis Magna. Sed Consequat, Leo Eget Bibendum, Sodales, Augue Velit Cursus Nunc, Quis Gravida Magna Mi A Libero.",
  },
  {
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
    title: "Best Quality Designs",
    content:
      "Sed Fringilla Mauris Sit Amet Nibh. Donec Sodales Sagittis Magna. Sed Consequat, Leo Eget Bibendum, Sodales, Augue Velit Cursus Nunc, Quis Gravida Magna Mi A Libero.",
  },
];

const card6 = [
  {
    image: {
      width: "60",
      height: "60",
      src: require("../assets/whatWeDo/web-design.svg"),
      alt: "Client Image",
      loading: "lazy",
    },
    client: {
      name: "Web Design",
      organization:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    backgroundColor: "transparent",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "23px",
    textColor: "#fff",
    textSize: "12",
  },
  {
    image: {
      width: "60",
      height: "60",
      src: require("../assets/whatWeDo/logo-design.svg"),
      alt: "Client Image",
      loading: "lazy",
    },
    client: {
      name: "Logo Design",
      organization:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    backgroundColor: "transparent",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "23px",
    textColor: "#fff",
    textSize: "12",
  },
  {
    image: {
      width: "60",
      height: "60",
      src: require("../assets/whatWeDo/creative-tools.svg"),
      alt: "Client Image",
      loading: "lazy",
    },
    client: {
      name: "Illustration",
      organization:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    backgroundColor: "transparent",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "23px",
    textColor: "#fff",
    textSize: "12",
  },
];

const card7 = [
  {
    counter: "150",
    counterColor: { color: "#6878D6" },
    content: "Projects Completed",
    contentColor: { color: "#242A56" },
  },
  {
    counter: "150",
    counterColor: { color: "#6878D6" },
    content: "Projects Completed",
    contentColor: { color: "#242A56" },
  },
  {
    counter: "150",
    counterColor: { color: "#6878D6" },
    content: "Projects Completed",
    contentColor: { color: "#242A56" },
  },
];

const card8 = [
  {
    selectOption: "Select Option",
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    productImages: [
      {
        image1: require("../assets/products/front.jpg").default,
        width: "100%",
        height: "100%",
      },
      {
        image2: require("../assets/products/side.jpg").default,
        width: "100%",
        height: "100%",
      },
    ],
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
  {
    selectOption: "Select Option",
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    productImages: [
      {
        image1: require("../assets/products/front.jpg").default,
        width: "100%",
        height: "100%",
      },
      {
        image2: require("../assets/products/side.jpg").default,
        width: "100%",
        height: "100%",
      },
    ],
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
  {
    selectOption: "Select Option",
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    productImages: [
      {
        image1: require("../assets/products/front.jpg").default,
        width: "100%",
        height: "100%",
      },
      {
        image2: require("../assets/products/side.jpg").default,
        width: "100%",
        height: "100%",
      },
    ],
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
];

const card9 = [
  {
    productImg: require("../assets/products/front.jpg").default,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    width: "324px",
    height: "104px",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
  {
    productImg: require("../assets/products/front.jpg").default,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    width: "324px",
    height: "104px",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
  {
    productImg: require("../assets/products/front.jpg").default,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    width: "324px",
    height: "104px",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
];

const card10 = [
  {
    cardImg: {
      image1: {
        width: "61",
        height: "61",
        src: "http://foodsinn.co/wp-content/uploads/2020/01/FastFoodicon.png",
      },
      image2: {
        width: "178",
        height: "20",
        src: "http://foodsinn.co/wp-content/uploads/2016/03/separator.png",
      },
    },
    title: "FAST FOOD",
    content: `Serving pure beef and chicken with simple and quality
		ingredients, bettered by adding onion, tomato ketchup and
    mustard in a sesame seed bun.`,
    width: "100%",
    height: "100%",
    borderColor: "#967758",
    backgroundColor: "#fff",
    fontFamily: "Roboto",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
  {
    cardImg: {
      image1: {
        width: "61",
        height: "61",
        src: "http://foodsinn.co/wp-content/uploads/2020/01/FastFoodicon.png",
      },
      image2: {
        width: "178",
        height: "20",
        src: "http://foodsinn.co/wp-content/uploads/2016/03/separator.png",
      },
    },
    title: "FAST FOOD",
    content: `Serving pure beef and chicken with simple and quality
		ingredients, bettered by adding onion, tomato ketchup and
    mustard in a sesame seed bun.`,
    width: "100%",
    height: "100%",
    borderColor: "#967758",
    backgroundColor: "#fff",
    fontFamily: "Roboto",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
  {
    cardImg: {
      image1: {
        width: "61",
        height: "61",
        src: "http://foodsinn.co/wp-content/uploads/2020/01/FastFoodicon.png",
      },
      image2: {
        width: "178",
        height: "20",
        src: "http://foodsinn.co/wp-content/uploads/2016/03/separator.png",
      },
    },
    title: "FAST FOOD",
    content: `Serving pure beef and chicken with simple and quality
		ingredients, bettered by adding onion, tomato ketchup and
    mustard in a sesame seed bun.`,
    width: "100%",
    height: "100%",
    borderColor: "#967758",
    backgroundColor: "#fff",
    fontFamily: "Roboto",
    headingColor: "black",
    headingSize: "23px",
    textColor: "black",
    textSize: "12",
  },
];

const card11 = [
  {
    name: "Chicken Corn Soup (single)",
    description: "Pure chicken stock with chicken minced, and bits of corn.",
    cost: "Rs. 230",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "grey",
    textSize: "12",
  },
  {
    name: "Chicken Corn Soup (single)",
    description: "Pure chicken stock with chicken minced, and bits of corn.",
    cost: "Rs. 230",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "grey",
    textSize: "12",
  },
  {
    name: "Chicken Corn Soup (single)",
    description: "Pure chicken stock with chicken minced, and bits of corn.",
    cost: "Rs. 230",
    backgroundColor: "#fff",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "black",
    headingSize: "23px",
    textColor: "grey",
    textSize: "12",
  },
];

const card12 = [
  {
    cardImage: "fa fa-opencart",
    cardTitle: "BRANDING",
    cardContent:
      "From initial naming to full visual roll-out we can look after everything brand.",
    iconColor: "red",
    width: "",
    height: "",
    backgroundColor: "black",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "23px",
    textColor: "#fff",
    textSize: "12",
  },
  {
    cardImage: "fa fa-opencart",
    cardTitle: "BRANDING",
    cardContent:
      "From initial naming to full visual roll-out we can look after everything brand.",
    iconColor: "red",
    width: "",
    height: "",
    backgroundColor: "black",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "23px",
    textColor: "#fff",
    textSize: "12",
  },
  {
    cardImage: "fa fa-opencart",
    cardTitle: "BRANDING",
    cardContent:
      "From initial naming to full visual roll-out we can look after everything brand.",
    iconColor: "red",
    width: "",
    height: "",
    backgroundColor: "black",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "23px",
    textColor: "#fff",
    textSize: "12",
  },
];

const card13 = [
  {
    cardImage: "fa fa-opencart",
    cardTitle: "STRATEGY",
    cardContent: `when we take on a project we like to do thing thoroughly, this means we follow a set of tasks to make things lovely. Firstly we really get to know the concept. This means talking to you, asking you difficult questions and throwing out some ideas along the way. Together we turn the brief into a story that allows us to get started on how the user will interact.  `,
    iconColor: "red",
    width: "",
    height: "",
    backgroundColor: "black",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "20px",
    textColor: "#fff",
    textSize: "12",
  },
  {
    cardImage: "fa fa-opencart",
    cardTitle: "STRATEGY",
    cardContent: `when we take on a project we like to do thing thoroughly, this means we follow a set of tasks to make things lovely. Firstly we really get to know the concept. This means talking to you, asking you difficult questions and throwing out some ideas along the way. Together we turn the brief into a story that allows us to get started on how the user will interact.  `,
    iconColor: "red",
    width: "",
    height: "",
    backgroundColor: "black",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "20px",
    textColor: "#fff",
    textSize: "12",
  },
  {
    cardImage: "fa fa-opencart",
    cardTitle: "STRATEGY",
    cardContent: `when we take on a project we like to do thing thoroughly, this means we follow a set of tasks to make things lovely. Firstly we really get to know the concept. This means talking to you, asking you difficult questions and throwing out some ideas along the way. Together we turn the brief into a story that allows us to get started on how the user will interact.  `,
    iconColor: "red",
    width: "",
    height: "",
    backgroundColor: "black",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#fff",
    headingSize: "20px",
    textColor: "#fff",
    textSize: "12",
  },
];

const card14 = [
  {
    cardImg: require("../assets/portfolio/portfolio1.jpg"),
    href: "#",
    height: "400px",
  },
  {
    cardImg: require("../assets/portfolio/portfolio1.jpg"),
    href: "#",
    height: "400px",
  },
  {
    cardImg: require("../assets/portfolio/portfolio1.jpg"),
    href: "#",
    height: "400px",
  },
];

const card15 = [
  {
    card: {
      image: "/assets/images/horizontal-pic2.png",
      number: "1",
      title: "Graphic design & Branding",
      content:
        "We build and activate brands throung cultural insight, strategic vision, and the power of emotion..",
      contentTextSize: "",
      contentTextColor: "#666",
      contentTextFamily: "teko, sans-serif",
      numberTextColor: "#ccc",
      numberTextSize: "60px",
      numberTextFamily: "teko, sans-serif",
      headerColor: "rgb(0, 0, 0)",
      headerTextSize: "25px",
      headerBackground: "none",
      headerTextFamily: "teko, sans-serif",
      buttonText: "Learn More",
      buttonTextColor: "#fff",
      buttonTextSize: "16px",
      buttonBackgroundColor: "#ff3600",
      buttonBorder: "1px solid transparent",
    },
  },
  {
    card: {
      image: "/assets/images/horizontal-pic2.png",
      number: "1",
      title: "Graphic design & Branding",
      content:
        "We build and activate brands throung cultural insight, strategic vision, and the power of emotion..",
      contentTextSize: "",
      contentTextColor: "#666",
      contentTextFamily: "teko, sans-serif",
      numberTextColor: "#ccc",
      numberTextSize: "60px",
      numberTextFamily: "teko, sans-serif",
      headerColor: "rgb(0, 0, 0)",
      headerTextSize: "25px",
      headerBackground: "none",
      headerTextFamily: "teko, sans-serif",
      buttonText: "Learn More",
      buttonTextColor: "#fff",
      buttonTextSize: "16px",
      buttonBackgroundColor: "#ff3600",
      buttonBorder: "1px solid transparent",
    },
  },
  {
    card: {
      image: "/assets/images/horizontal-pic2.png",
      number: "1",
      title: "Graphic design & Branding",
      content:
        "We build and activate brands throung cultural insight, strategic vision, and the power of emotion..",
      contentTextSize: "",
      contentTextColor: "#666",
      contentTextFamily: "teko, sans-serif",
      numberTextColor: "#ccc",
      numberTextSize: "60px",
      numberTextFamily: "teko, sans-serif",
      headerColor: "rgb(0, 0, 0)",
      headerTextSize: "25px",
      headerBackground: "none",
      headerTextFamily: "teko, sans-serif",
      buttonText: "Learn More",
      buttonTextColor: "#fff",
      buttonTextSize: "16px",
      buttonBackgroundColor: "#ff3600",
      buttonBorder: "1px solid transparent",
    },
  },
];

const card16 = [
  {
    cardItems: {
      image: require("../assets/pic12.png"),
    },
  },
  {
    cardItems: {
      image: require("../assets/pic12.png"),
    },
  },
  {
    cardItems: {
      image: require("../assets/pic12.png"),
    },
  },
];

const card17 = [
  {
    image:
      "https://i1.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/tech-lookbook-04.jpg?fit=707%2C782&ssl=1",
    imageWidth: "",
    imageHeight: "",
    hoveredImage:
      "https://i1.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/tech-lookbook-04.jpg?fit=707%2C782&ssl=1",
    hoveredImageWidth: "",
    hoveredImageHeight: "",
    productName: "Mackbook Pro 13",
    productPrice: "$499",
  },
  {
    image:
      "https://i1.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/tech-lookbook-04.jpg?fit=707%2C782&ssl=1",
    imageWidth: "",
    imageHeight: "",
    hoveredImage:
      "https://i1.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/tech-lookbook-04.jpg?fit=707%2C782&ssl=1",
    hoveredImageWidth: "",
    hoveredImageHeight: "",
    productName: "Mackbook Pro 13",
    productPrice: "$499",
  },
  {
    image:
      "https://i1.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/tech-lookbook-04.jpg?fit=707%2C782&ssl=1",
    imageWidth: "",
    imageHeight: "",
    hoveredImage:
      "https://i1.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/tech-lookbook-04.jpg?fit=707%2C782&ssl=1",
    hoveredImageWidth: "",
    hoveredImageHeight: "",
    productName: "Mackbook Pro 13",
    productPrice: "$499",
  },
];
const card20 = [
  {
    image: "http://paul-themes.com/html/lewis/demo/img/pic39.jpg",
    heading: "Brole",
    subHeading: "Mobile App",
    headingColor: "#fff",
    headingFontSize: "40px",
    headingFontFamily: "abril fatface,cursive",
    headingFontWeight: "700",
    subHeadingColor: "#fff",
    subHeadingFontSize: "16px",
    subHeadingFontFamily: "karla, sans-serif",
    subHeadingFontWeight: "400",
    backgroundColor: "#191a1e",
    date: "2020",
  },
];

const card21 = [
  {
    image: "http://paul-themes.com/html/lewis/demo/img/pic117.jpg",
    heading: "Mobile",
    subHeading: "Lorem Ipsum Lorem Ipsum",
    headingFontColor: "#fff",
    headingFontSize: "12px",
    headingFontFamily: "karla, sans-serif",
    headingFontWeight: "400",
    subHeadingFontColor: "#fff",
    subHeadingFontSize: "24px",
    subHeadingFontFamily: "karla, sans-serif",
    subHeadingFontWeight: "700",
    dateFontColor: "#fff",
    dateFontSize: "16px",
    dateFontFamily: "karla, sans-serif",
    dateFontWeight: "400",
    date: "2020",
  },
  {
    image: "http://paul-themes.com/html/lewis/demo/img/pic117.jpg",
    heading: "Mobile",
    subHeading: "Lorem Ipsum Lorem Ipsum",
    headingFontColor: "#fff",
    headingFontSize: "12px",
    headingFontFamily: "karla, sans-serif",
    headingFontWeight: "400",
    subHeadingFontColor: "#fff",
    subHeadingFontSize: "24px",
    subHeadingFontFamily: "karla, sans-serif",
    subHeadingFontWeight: "700",
    dateFontColor: "#fff",
    dateFontSize: "16px",
    dateFontFamily: "karla, sans-serif",
    dateFontWeight: "400",
    date: "2020",
  },
  {
    image: "http://paul-themes.com/html/lewis/demo/img/pic117.jpg",
    heading: "Mobile",
    subHeading: "Lorem Ipsum Lorem Ipsum",
    headingFontColor: "#fff",
    headingFontSize: "12px",
    headingFontFamily: "karla, sans-serif",
    headingFontWeight: "400",
    subHeadingFontColor: "#fff",
    subHeadingFontSize: "24px",
    subHeadingFontFamily: "karla, sans-serif",
    subHeadingFontWeight: "700",
    dateFontColor: "#fff",
    dateFontSize: "16px",
    dateFontFamily: "karla, sans-serif",
    dateFontWeight: "400",
    date: "2020",
  },
];

export {
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  card8,
  card9,
  card10,
  card11,
  card12,
  card13,
  card14,
  card15,
  card16,
  card17,
  card20,
  card21,
};
