import React from "react";
import PropTypes from "prop-types";

import { ReUsableNavbar8 } from "@inertiasoft/re-usable-nav-bars";
import { ReUsableFooter10 } from "@inertiasoft/re-usable-footers";
import { topNavBar8 } from "../../constants/menu";
import { footer10 } from "../../constants/footer";

import "./layout.css";

const Layout = ({ children }) => {
  return (
    <div className="Layout">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <ReUsableNavbar8 data={topNavBar8} />
          </div>
        </div>

        <div className="body">{children}</div>
        <div className="row">
          <div className="col-md-12">
            <ReUsableFooter10 data={footer10} />
          </div>
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
