import React from "react";
import "./process.css";

import { ReUsableCard13 } from "@inertiasoft/re-usable-cards";

import services from "../../constants/services";

class Process extends React.Component {
  title = "About Us";

  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="Process">
        <div className="row process">
          <div className="col-md-12 ">
            <h2>
              THE <span className="x-men-color">PLAN</span> OF ACTION
            </h2>
            <br />
          </div>
        </div>
        <br />
        <br />
        <div className="row justify-content-center flex-wrap">
          {services.ourStrategyDetails.map((item, index) => (
            <div className="col-lg-3">
              <ReUsableCard13 data={item} key={index} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Process;
