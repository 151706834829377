import React from "react";
import "./portfolio.css";
import PropTypes from "prop-types";
import { ReUsableCard14 } from "@inertiasoft/re-usable-cards";

class Portfolio extends React.Component {
  title = "About Us";

  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <div className="Portfolio">
        <div className="row d-flex justify-content-center m-2">
          {data.map((item, index) => (
            <div className="col-md-5 card p-0" key={index}>
              <ReUsableCard14 data={item} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Portfolio.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};

export default Portfolio;
