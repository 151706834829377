// const services = [
//   {
//     cardImg: {
//       width: "80",
//       height: "80",
//       src:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/design-service.svg",
//       alt: "Card-Image",
//       loading: "lazy",
//       srcSet:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/design-service.svg 100w",
//       sizes: "(max-width: 80px) 80vw, 80px",
//       href: "test",
//     },
//     cardTitle: { content: "Design" },
//     cardData: {
//       content:
//         "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
//     },
//   },
//   {
//     cardImg: {
//       width: "80",
//       height: "80",
//       src:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/development-service.svg",
//       alt: "Card-Image",
//       loading: "lazy",
//       srcSet:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/development-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/development-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/development-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/development-service.svg 100w",
//       sizes: "(max-width: 80px) 80vw, 80px",
//       href: "test",
//     },
//     cardTitle: { content: "Development" },
//     cardData: {
//       content:
//         "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
//     },
//   },
//   {
//     cardImg: {
//       width: "80",
//       height: "80",
//       src:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/digital-marketing-service.svg",
//       alt: "Card-Image",
//       loading: "lazy",
//       srcSet:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/digital-marketing-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/digital-marketing-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/digital-marketing-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/digital-marketing-service.svg 100w",
//       sizes: "(max-width: 80px) 80vw, 80px",
//       href: "test",
//     },
//     cardTitle: { content: "Marketing" },
//     cardData: {
//       content:
//         "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
//     },
//   },
//   {
//     cardImg: {
//       width: "80",
//       height: "80",
//       src:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/social-media-service.svg",
//       alt: "Card-Image",
//       loading: "lazy",
//       srcSet:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/social-media-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/social-media-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/social-media-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/social-media-service.svg 100w",
//       sizes: "(max-width: 80px) 80vw, 80px",
//       href: "test",
//     },
//     cardTitle: { content: "Social Media" },
//     cardData: {
//       content:
//         "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
//     },
//   },
//   {
//     cardImg: {
//       width: "80",
//       height: "80",
//       src:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/ecommerce-service.svg",
//       alt: "Card-Image",
//       loading: "lazy",
//       srcSet:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/ecommerce-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/ecommerce-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/ecommerce-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/ecommerce-service.svg 100w",
//       sizes: "(max-width: 80px) 80vw, 80px",
//       href: "test",
//     },
//     cardTitle: { content: "eCommerce" },
//     cardData: {
//       content:
//         "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
//     },
//   },
//   {
//     cardImg: {
//       width: "80",
//       height: "80",
//       src:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/support-service.svg",
//       alt: "Card-Image",
//       loading: "lazy",
//       srcSet:
//         "https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/support-service.svg 150w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/support-service.svg 300w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/support-service.svg 1024w, https://websitedemos.net/agency-02/wp-content/uploads/sites/30//2020/06/support-service.svg 100w",
//       sizes: "(max-width: 80px) 80vw, 80px",
//       href: "test",
//     },
//     cardTitle: { content: "Help & Support" },
//     cardData: {
//       content:
//         "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
//     },
//   },
// ];

const services = {
  whatWeDoDetails: [
    {
      cardImage: "fa fa-opencart",
      cardTitle: "Software Development",
      cardContent:
        "Being able to grasp the vastly increasing knowledge on software engineering and development, we have enabled ourselves to deliver solutions built with the latest technologies and tailored to meet your needs. This advancement has allowed us to set ourselves apart from others and offer our customers efficient and operative softwares.",
      iconColor: "red",
      width: "",
      height: "",
      backgroundColor: "black",
      fontFamily: "Open Sans, sans-serif",
      headingColor: "#fff",
      headingSize: "23px",
      textColor: "#fff",
      textSize: "12",
    },
    {
      cardImage: "fa fa-opencart",
      cardTitle: "Web and Mobile Development",
      cardContent:
        "As the world of web and mobile applications has exceeded barriers, InertiaSoft seeks to provide its customers with the most up to date and practical solutions. With our experienced developers, we look forward to meet your demands and produce quality products, which include responsive websites and fully functional mobile applications, that are a class apart.",
      iconColor: "red",
      width: "",
      height: "",
      backgroundColor: "black",
      fontFamily: "Open Sans, sans-serif",
      headingColor: "#fff",
      headingSize: "23px",
      textColor: "#fff",
      textSize: "12",
    },
    {
      cardImage: "fa fa-opencart",
      cardTitle: "Professional Outsourcing",
      cardContent:
        "The most important asset for a project is its resource: The People. We have software experts who have immense experience in working remote and in-house projects. Their communication skills and professionalism make your project's transition from plan to implementation swift. We ensure that your mission is depicted through your projects.",
      iconColor: "red",
      width: "",
      height: "",
      backgroundColor: "black",
      fontFamily: "Open Sans, sans-serif",
      headingColor: "#fff",
      headingSize: "23px",
      textColor: "#fff",
      textSize: "12",
    },
  ],
  ourStrategyDetails: [
    {
      cardImage: "fa fa-opencart",
      cardTitle: "Brainstorming",
      cardContent: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.  `,
      iconColor: "red",
      width: "",
      height: "",
      backgroundColor: "black",
      fontFamily: "Open Sans, sans-serif",
      headingColor: "#fff",
      headingSize: "23px",
      textColor: "#fff",
      textSize: "12",
    },
    {
      cardImage: "fa fa-opencart",
      cardTitle: "Strategize",
      cardContent: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
      iconColor: "red",
      width: "",
      height: "",
      backgroundColor: "black",
      fontFamily: "Open Sans, sans-serif",
      headingColor: "#fff",
      headingSize: "23px",
      textColor: "#fff",
      textSize: "12",
    },
    {
      cardImage: "fa fa-opencart",
      cardTitle: "Achieve",
      cardContent: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
      iconColor: "red",
      width: "",
      height: "",
      backgroundColor: "black",
      fontFamily: "Open Sans, sans-serif",
      headingColor: "#fff",
      headingSize: "23px",
      textColor: "#fff",
      textSize: "12",
    },
  ],
};

export default services;
