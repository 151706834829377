const topNavBar8 = {
  logo: {
    image: "https://i.gifer.com/V6D.gif",
    href: "/home",
  },
  navigation: {
    leftNav: [
      { content: "HOME", href: "/home" },
      { content: "SERVICES", href: "/#services" },
      { content: "TESTIMONIALS", href: "/#portfolio" },
      { content: "ABOUT", href: "/#about" },
    ],
    rightNav: [
      { content: "WEBSITES", href: "/websites" },
      { content: "LOGO", href: "/logo" },
      { content: "ILLUSTRATION", href: "/illustration" },
      { content: "CONTACT US", href: "/#contactus" },
    ],
  },
  navigationColor: "rgba(0,0,0,0.9)",
  navigationPosition: "relative",
  navItemColor: "transparent",
  navItemColorHover: "rgba(0,0,0,0.1)",
  color: "white",
};

export { topNavBar8 };
