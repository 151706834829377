import React from "react";
import "./websites.css";
import { connect } from "react-redux";
import Layout from "../../commons/layout/layout";
import Header from "../../components/secondry-header/header";
import Portfolio from "../../components/portfolio/portfolio";
import SecondryHeader from "../../constants/secondary-header";
import { websites } from "../../constants/portfolio";

function mapStateToProps(state) {
  return {
    isloading: state.login.isloading,
  };
}

class Websites extends React.Component {
  title = "Welcome to Home Page";

  constructor() {
    super();
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div className="Websites">
        <Layout>
          <div className="row">
            <div className="col-md-12">
              <Header data={SecondryHeader[0]} />
            </div>
          </div>
          <div className="row" id="portfolio">
            <div className="col-md-12">
              <Portfolio data={websites} />
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Websites);
