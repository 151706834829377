import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute/protectedRoute";

import Dashboard from "./components/dashboard/dashboard";
import Home from "./pages/home/home";
import Websites from "./pages/websites/websites";
import Logo from "./pages/logo/logo";
import Illustration from "./pages/illustration/illustration";

function App() {
  return (
    <div className="App">
      <div className="">
        <Router>
          <Switch>
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/websites" component={Websites} />
            <Route exact path="/logo" component={Logo} />
            <Route exact path="/illustration" component={Illustration} />
            <Route exact path="/" component={Home} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
