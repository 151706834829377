import React from "react";
import "./home.css";
import { connect } from "react-redux";

import ReUsableSlider5 from "@inertiasoft/re-usable-slider-5";
import Layout from "../../commons/layout/layout";
import Header from "../../components/header/header";
import WeAre from "../../components/we-are/we-are";
import Services from "../../components/services/services";
import Process from "../../components/process/process";
import ContactForm from "../../components/contact-form/contact-form";

// import { ReUsableSlider8 } from "@inertiasoft/re-usable-sliders";
// import { testimonials } from "../../constants/testimonials";
// import { ReUsableSlider1 } from "@inertiasoft/re-usable-sliders";

import { testimonial5 } from "../../constants/testimonials";

function mapStateToProps(state) {
  return {
    isloading: state.login.isloading,
  };
}

class Home extends React.Component {
  title = "Welcome to Home Page";

  constructor() {
    super();
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div className="Home">
        <Layout>
          <div className=" ">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <WeAre />
              </div>
            </div>
            <div className="row line" />
            <div className="row" id="services">
              <div className="col-md-12">
                <Services />
              </div>
            </div>
            <div className="row line" />
            <div className="row">
              <div className="col-md-12">
                <Process />
              </div>
            </div>
            <div className="row line" />

            <div className="row d-flex justify-content-center" id="portfolio">
              <div className="col-12">
                <h2 className="text-center">
                  TEST<span className="x-men-color">IMONIALS</span>
                </h2>
              </div>
              <div className="col-12">
                <ReUsableSlider5 data={testimonial5} />
              </div>
            </div>
            <div className="row" id="contactus">
              <div className="col-md-12">
                <ContactForm />
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Home);
