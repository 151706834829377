import slider1 from "../assets/testimonial/Slider-1-(1910x690)BG-Only.jpg";
import slider2 from "../assets/testimonial/Slider-2-(1910x690)BG-Only.jpg";
import slider3 from "../assets/testimonial/Slider-3-(1910x690)BG-Only.jpg";

const testimonials = [
  {
    image:
      "https://www.indiewire.com/wp-content/uploads/2020/06/shutterstock_editorial_10237806fm.jpg",
    comment:
      "We are thrilled the work Xman done creating our website we are truly proud of",
    name: "Daniel Radcliffe",
    width: "100%",
    height: "100%",
  },
  {
    image:
      "https://akns-images.eonline.com/eol_images/Entire_Site/2019428/rs_1024x759-190528142154-1024--kit-harington.cm.52819.jpg",
    comment:
      "You answer my questions, always takes care of problems, and I never have a hassle.",
    name: "Kit Harington",
    width: "100%",
    height: "100%",
  },
];

const testimonial1 = {
  img1:
    "https://images.pexels.com/photos/2246950/pexels-photo-2246950.jpeg?cs=rgb",
  img1_alt: "img1",
  img2: "https://images.pexels.com/photos/68704/pexels-photo-68704.jpeg?cs=rgb",
  img2_alt: "img2",
  img3:
    'https://images.pexels.com/photos/2749090/pexels-photo-2749090.jpeg?cs=rgb"',
  img3_alt: "img3",
  img4:
    "https://images.pexels.com/photos/2319032/pexels-photo-2319032.jpeg?cs=rgb",
  img4_alt: "img4",
};
const testimonial2 = [
  {
    img:
      "https://images.unsplash.com/photo-1480796927426-f609979314bd?w=500&amp;q=50",
    img_alt: "img1",
  },
  {
    img:
      "https://images.unsplash.com/photo-1492571350019-22de08371fd3?w=500&amp;q=50",
    img_alt: "img2",
  },
  {
    img:
      "https://images.unsplash.com/photo-1542051841857-5f90071e7989?w=500&amp;q=50",
    img_alt: "img3",
  },
  {
    img:
      "https://images.unsplash.com/photo-1526481280693-3bfa7568e0f3?w=500&amp;q=50",
    img_alt: "img4",
  },
  {
    img:
      "https://images.unsplash.com/photo-1528360983277-13d401cdc186?w=500&amp;q=50",
    img_alt: "img5",
  },
  {
    img:
      "https://images.unsplash.com/photo-1542931287-023b922fa89b?w=500&amp;q=50",
    img_alt: "img6",
  },
];
const testimonial3 = [
  {
    img: "http://www.icons101.com/icon_png/size_256/id_79394/youtube.png",
    card: "Card 1",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "GO somwhere",
    backgroundColor: "white",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "blue",
    headingSize: "20px",
    textColor: "black",
    textSize: "12",
  },
  {
    img:
      "https://images.pexels.com/photos/2880506/pexels-photo-2880506.jpeg?auto=compress&cs=tinysrgb&h=350",
    card: "Card 2",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Go somewhere",
    backgroundColor: "white",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "blue",
    headingSize: "20px",
    textColor: "black",
    textSize: "12",
  },
  {
    img:
      "https://images.pexels.com/photos/2880506/pexels-photo-2880506.jpeg?auto=compress&cs=tinysrgb&h=350",
    card: "Card 3",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Go somewhere",
    backgroundColor: "white",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "blue",
    headingSize: "20px",
    textColor: "black",
    textSize: "12",
  },
  {
    img:
      "https://images.pexels.com/photos/2880506/pexels-photo-2880506.jpeg?auto=compress&cs=tinysrgb&h=350",
    card: "Card 4",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Go somewhere",
    backgroundColor: "white",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "blue",
    headingSize: "20px",
    textColor: "black",
    textSize: "12",
  },
  {
    img:
      "https://images.pexels.com/photos/2880506/pexels-photo-2880506.jpeg?auto=compress&cs=tinysrgb&h=350",
    card: "Card 5",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Go somewhere",
    backgroundColor: "white",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "blue",
    headingSize: "20px",
    textColor: "black",
    textSize: "12",
  },
  {
    img:
      "https://images.pexels.com/photos/2880506/pexels-photo-2880506.jpeg?auto=compress&cs=tinysrgb&h=350",
    card: "Card 6",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Go somewhere",
    backgroundColor: "white",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "blue",
    headingSize: "20px",
    textColor: "black",
    textSize: "12",
  },
  {
    img:
      "https://images.pexels.com/photos/2880506/pexels-photo-2880506.jpeg?auto=compress&cs=tinysrgb&h=350",
    card: "Card 7",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Go somewhere",
    backgroundColor: "white",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "blue",
    headingSize: "20px",
    textColor: "black",
    textSize: "12",
  },
];

const testimonial4 = {
  label: "Get Started",

  img1: "http://www.icons101.com/icon_png/size_256/id_79394/youtube.png",
  backgroundColor1: "#e53935",
  title1: "This is a very cool feature",
  subtitle1: "Just using this will blow your mind.",

  img2: "http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png",
  backgroundColor2: "#1e88e5",
  title2: "Ever wanted to be popular?",
  subtitle2: "Well just mix two colors and your are good to go!",

  img3:
    "http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png",
  backgroundColor3: "#43a047",
  title3: "May the force be with you",
  subtitle3:
    "The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe.",
};

const testimonial5 = [
  {
    title: "Shawn Paul",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utabore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    button: "Read More",
    image: `${slider1}`,
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
  {
    title: "Riley Aniston",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utlabore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    button: "Discover",
    image: `${slider2}`,
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
  {
    title: "Mike Andrew",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
    button: "Buy now",
    image: `${slider3}`,
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
];

const testimonial6 = [
  {
    img: "https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg",
    name: "Shirley Fultz",
    profession: "Designer",
    content:
      "Its freeing to be able to catch up on customized news and not be distracted by a social media element on the same site",
    rating: 2.6,
  },

  {
    img: "https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg",
    name: "Daniel Keystone",
    profession: "Architect",
    content:
      "The simple and intuitive design makes it easy for me use. I highly recommend Fetch to my peers.",
    rating: 3.403,
  },

  {
    img: "https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg",
    name: "Theo Sorel",
    profession: "Designer",
    content:
      "I enjoy catching up with Fetch on my laptop, or on my phone when I am on the go!",
    rating: 4,
  },
];

const testimonial7 = [
  {
    type: "NEW",
  },
  {
    type: "CHEF SPECIAL",
  },

  {
    type: "BURGERS",
  },
  {
    type: "SANDWICHES",
  },
  {
    type: "FRIED CHICKEN",
  },
  {
    type: "STEAKS",
  },
  {
    type: "PASTA",
  },
  {
    type: "CHINESE",
  },
  {
    type: "NOODLES & RICE",
  },
  {
    type: "BBQ",
  },
  {
    type: "CHICKEN KARAHI",
  },
  {
    type: "MUTTON KARAHI",
  },
  {
    type: "HANDI",
  },
  {
    type: "ROLLS",
  },
  {
    type: "MOCKTAILS & SHAKES",
  },
  {
    type: "DESSERTS",
  },
  {
    type: "DEALS",
  },
  {
    type: "PLATTERS",
  },
  {
    type: "ROTI PARATHA",
  },
  {
    type: "COLD BEVERAGES",
  },
  {
    type: "ADD ONS",
  },
];
const testimonial8 = [
  {
    image:
      "https://www.indiewire.com/wp-content/uploads/2020/06/shutterstock_editorial_10237806fm.jpg",
    comment:
      "We are thrilled the work Xman done creating our website we are truly proud of",
    name: "Daniel Radcliffe",
    fontFamily: "Open Sans, sans-serif",
    textColor: "black",
    textSize: "16px",
  },
  {
    image:
      "https://akns-images.eonline.com/eol_images/Entire_Site/2019428/rs_1024x759-190528142154-1024--kit-harington.cm.52819.jpg",
    comment:
      "You answer my questions, always takes care of problems, and I never have a hassle.",
    name: "Kit Harington",
    fontFamily: "Open Sans, sans-serif",
    textColor: "white",
    textSize: "16px",
  },
  {
    image:
      "https://dailytimes.com.pk/assets/uploads/2020/06/Shahid-Afridi-Age-Height-Weight-Wife-Affairs_resize.png",
    comment:
      "We would whole heartedly recommend the brilliant team Xman. They have built us an excellent cafe, backed up by honest & reliable service & business development.",
    name: "Shahid Afridi",
    fontFamily: "Open Sans, sans-serif",
    textColor: "red",
    textSize: "16px",
  },
];
const testimonial9 = [
  {
    imageNo: 0,
    image: "https://s14.postimg.cc/bnwpgsqnl/pixel1.png",
    thumbnail: "https://s14.postimg.cc/z24osq8kt/pixel1.png",
    class: "active",
  },
  {
    imageNo: 1,
    image: "https://s14.postimg.cc/k665l54w1/pixel2.png",
    thumbnail: "https://s14.postimg.cc/l8gc3onp9/pixel2.png",
    class: "",
  },
  {
    imageNo: 2,
    image: "https://s14.postimg.cc/4xg87dycx/pixel5.png",
    thumbnail: "https://s14.postimg.cc/8ttk3djcd/pixel5.png",
    class: "",
  },
  {
    imageNo: 3,
    image: "https://s14.postimg.cc/4kou178dd/pixel3.png",
    thumbnail: "https://s14.postimg.cc/4kou178d9/pixel3.png",
    class: "",
  },
  {
    imageNo: 4,
    image: "https://s14.postimg.cc/almiy9n9t/pixel4.png",
    thumbnail: "https://s14.postimg.cc/9jccfq4gd/pixel4.png",
    class: "",
  },
];
const testimonial10 = [
  {
    imageNo: 0,
    image:
      "https://www.pngitem.com/pimgs/m/188-1880470_characters-holding-a-five-star-rating-illustrating-online.png",
    comment:
      "This is my comment 1. This is my comment 1. This is my comment 1. This is my comment 1.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "black",
    textSize: "16px",
  },
  {
    imageNo: 1,
    image: "https://loyaltylion.com/app/uploads/2016/05/BLOG-CTA.png",
    comment:
      "This is my comment 2. This is my comment 2. This is my comment 2. This is my comment 2.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "red",
    textSize: "16px",
  },
  {
    imageNo: 2,
    image:
      "https://gatherup.com/wp-content/uploads/2019/05/do-reviews-impact-sales-statistics-1024x488.png",
    comment:
      "This is my comment 3. This is my comment 3. This is my comment 3. This is my comment 3.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "orange",
    textSize: "16px",
  },
  {
    imageNo: 3,
    image:
      "https://webhose.io/wp-content/uploads/2017/03/rated-online-reviews.png",
    comment:
      "This is my comment 4. This is my comment 4. This is my comment 4. This is my comment 4.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "pink",
    textSize: "16px",
  },
  {
    imageNo: 4,
    image:
      "https://prestigesocialmedia.com.au/wp-content/uploads/2019/06/51.png",
    comment:
      "This is my comment 5. This is my comment 5. This is my comment 5. This is my comment 5.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "white",
    textSize: "16px",
  },
];

export {
  testimonials,
  testimonial1,
  testimonial2,
  testimonial3,
  testimonial4,
  testimonial5,
  testimonial6,
  testimonial7,
  testimonial8,
  testimonial9,
  testimonial10,
};
