import React from "react";
import "./we-are.css";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    isloading: state.login.isloading,
  };
}

class WeAre extends React.Component {
  title = "Welcome to Home Page";

  constructor() {
    super();
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div className="We-Are" id="about">
        <div className="row">
          <div className="col-md-8 p-5 d-flex flex-column justify-content-center align-items-center">
            <h2>
              DESIGN <span className="x-men-color">IS</span> EVERYWHERE
            </h2>
            <div className="text">
              <p>
                From the dress you are wearing to the smart phone you are
                holding, its design
                <br />
                <br />
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using Content
                here, content here, making it look like readable English.
              </p>
              <hr />
              <ul className="nav d-flex justify-content-center align-items-center">
                <li className="nav-item ">
                  <a className="nav-link text-light" href="#services">
                    WHAT WE DO
                  </a>
                  <hr />
                </li>
                <li className="nav-item ">
                  <a className="nav-link text-light" href="/websites">
                    WEBSITES
                  </a>
                  <hr />
                </li>
                <li className="nav-item ">
                  <a className="nav-link text-light" href="logo">
                    LOGO
                  </a>
                  <hr />
                </li>
                <li className="nav-item ">
                  <a className="nav-link text-light" href="illustration">
                    ILLUSTRATION
                  </a>
                  <hr />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <img src={require("../../assets/We-are-inertia.gif")} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(WeAre);
