const websites = [
  {
    cardImg: require("../assets/Websites/Khaki-Experimental-Art-Deco-Fashion-Website.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Websites/Khaki-Experimental-Art-Deco-Fashion-Website-1.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Websites/Khaki-Experimental-Art-Deco-Fashion-Website-2.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Websites/Khaki-Experimental-Art-Deco-Fashion-Website-3.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Websites/Khaki-Experimental-Art-Deco-Fashion-Website-4.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Websites/Khaki-Experimental-Art-Deco-Fashion-Website-5.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Websites/Khaki-Experimental-Art-Deco-Fashion-Website-6.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Websites/Khaki-Experimental-Art-Deco-Fashion-Website-7.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
];

const logo = [
  {
    cardImg: require("../assets/Logo/logo-1.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Logo/logo-2.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Logo/logo-3.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Logo/logo-4.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Logo/logo-5.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Logo/logo-6.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Logo/logo-7.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/Logo/logo-8.jpg"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
];

const illustration = [
  {
    cardImg: require("../assets/illustration/Ill-1.gif"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/illustration/Ill-2.gif"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/illustration/Ill-3.gif"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/illustration/Ill-4.gif"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/illustration/Ill-5.gif"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/illustration/Ill-6.gif"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/illustration/Ill-7.gif"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
  {
    cardImg: require("../assets/illustration/Ill-8.gif"),
    href: "#",
    width: "100%",
    height: "55vh",
  },
];

module.exports = { websites, logo, illustration };
