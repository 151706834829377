import React from "react";
// import "./dashboard.css";
import Layout from "../../commons/layout/layout";

class Dashboard extends React.Component {
  title = "Dashboard";

  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="Login">
        <Layout>
          <h2>{this.title}</h2>
        </Layout>
      </div>
    );
  }
}

export default Dashboard;
