const SecondryHeader = [
  {
    title: "WEB LAYOUTS",
    heading: "Websites promotes you business 24/7. No employee will do that.",
    description:
      "Get your website along with mobile compatibility.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    image: require("../assets/web.gif"),
  },
  {
    title: "LOGO DESIGN",
    heading: "Strongest logos depicts the perfection of work.",
    description:
      "The function of logo is letting design function more relevent and creative.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    image: require("../assets/Gif-for-logo-page.gif"),
  },
  {
    title: "ILLUSTRATION",
    heading: "The Imagination Is The Beginning of Creation.",
    description:
      "Visual imaginary best known for Illustrating indicates the result of an idea.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    image: require("../assets/illus.gif"),
  },
];

export default SecondryHeader;
